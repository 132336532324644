// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("F:\\Zlecenia\\sekr-gb\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("F:\\Zlecenia\\sekr-gb\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("F:\\Zlecenia\\sekr-gb\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("F:\\Zlecenia\\sekr-gb\\src\\pages\\blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cennik-js": () => import("F:\\Zlecenia\\sekr-gb\\src\\pages\\cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-page-2-js": () => import("F:\\Zlecenia\\sekr-gb\\src\\pages\\page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-uslugi-js": () => import("F:\\Zlecenia\\sekr-gb\\src\\pages\\uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */),
  "component---src-pages-uslugi-implantologia-js": () => import("F:\\Zlecenia\\sekr-gb\\src\\pages\\uslugi\\implantologia.js" /* webpackChunkName: "component---src-pages-uslugi-implantologia-js" */),
  "component---src-pages-uslugi-kompleksowa-opieka-js": () => import("F:\\Zlecenia\\sekr-gb\\src\\pages\\uslugi\\kompleksowa-opieka.js" /* webpackChunkName: "component---src-pages-uslugi-kompleksowa-opieka-js" */),
  "component---src-pages-uslugi-medycyna-estetyczna-js": () => import("F:\\Zlecenia\\sekr-gb\\src\\pages\\uslugi\\medycyna-estetyczna.js" /* webpackChunkName: "component---src-pages-uslugi-medycyna-estetyczna-js" */),
  "component---src-pages-uslugi-stomatologia-estetyczna-js": () => import("F:\\Zlecenia\\sekr-gb\\src\\pages\\uslugi\\stomatologia-estetyczna.js" /* webpackChunkName: "component---src-pages-uslugi-stomatologia-estetyczna-js" */),
  "component---src-pages-zespol-js": () => import("F:\\Zlecenia\\sekr-gb\\src\\pages\\zespol.js" /* webpackChunkName: "component---src-pages-zespol-js" */),
  "component---src-pages-zespol-dorota-maliszewska-js": () => import("F:\\Zlecenia\\sekr-gb\\src\\pages\\zespol\\dorota-maliszewska.js" /* webpackChunkName: "component---src-pages-zespol-dorota-maliszewska-js" */),
  "component---src-pages-zespol-radoslaw-lysek-js": () => import("F:\\Zlecenia\\sekr-gb\\src\\pages\\zespol\\radoslaw-lysek.js" /* webpackChunkName: "component---src-pages-zespol-radoslaw-lysek-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "F:\\Zlecenia\\sekr-gb\\.cache\\data.json")

